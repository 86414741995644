import { useCallback, useReducer } from "react";
import { array, number, object, string } from "yup";
import { hereMapsLayer } from "../../../config/layers/baseLayers";
import {
  fireFeatures,
  incidentIcons,
  potentialImpactsCounts,
  stateBorder,
  stateForestPlantationLayer,
  type LayerConfig,
  type SubLayerConfig,
} from "../../../config/layers/layers";
import {
  SessionStorageKeys,
  type SessionStorageKey,
} from "../../../config/storage";

// Currently only useful for layers drawer counts. Evaluate inversion of
// control.
type ActiveLayerSource = "layers-drawer" | "option-layer" | "base-map";

export type ActiveLayerState = {
  opacity: number;
};

export type SubLayerState<SubLayer extends SubLayerConfig> =
  ActiveLayerState & {
    [Key in SubLayer["id"]]?: ActiveLayerState & { isActive: boolean };
  };

export type LayerConfigState<Config extends LayerConfig> =
  Config["subLayers"] extends readonly SubLayerConfig[]
    ? ActiveLayerState & {
        [Key in Config["subLayers"][number]["id"]]?: ActiveLayerState & {
          isActive: boolean;
        };
      }
    : never;

export const activeLayerFromConfig = <
  Config extends LayerConfig,
  State extends ActiveLayerState = LayerConfigState<Config>,
>({
  layer,
  source,
  state,
}: {
  layer: Config;
  source: ActiveLayerSource;
  state: State;
}): ActiveLayer<State> => {
  return {
    id: layer.id,
    source,
    state,
  };
};

export type ActiveLayer<S extends ActiveLayerState = ActiveLayerState> = {
  id: string;
  source: ActiveLayerSource;
  state: S;
};

export type ActiveLayersState = {
  activeLayers: Map<string, ActiveLayer>;
};

type ActivateLayerAction<S extends ActiveLayerState = ActiveLayerState> = {
  type: "activateLayer";
  payload: {
    id: string;
    source: ActiveLayerSource;
    state?: Partial<S>;
  };
};

type DeactivateLayerAction = {
  type: "deactivateLayer";
  payload: {
    id: string;
  };
};

type ClearLayersAction = {
  type: "clearLayers";
  payload: {
    source: ActiveLayerSource;
  };
};

type SetBaseMapAction = {
  type: "setBaseMap";
  payload: {
    id: string;
  };
};

type SetLayerStateAction<S extends ActiveLayerState = ActiveLayerState> = {
  type: "setLayerState";
  payload: {
    id: string;
    source: ActiveLayerSource;
    state: Partial<S>;
  };
};

type ActiveLayersAction =
  | ActivateLayerAction
  | DeactivateLayerAction
  | ClearLayersAction
  | SetBaseMapAction
  | SetLayerStateAction;

const activeLayersReducer = (
  state: ActiveLayersState,
  action: ActiveLayersAction,
): ActiveLayersState => {
  switch (action.type) {
    case "activateLayer": {
      return {
        ...state,
        activeLayers: new Map(state.activeLayers).set(action.payload.id, {
          id: action.payload.id,
          source: action.payload.source,
          // Default opacity to 1
          state: { opacity: 1, ...action.payload.state },
        }),
      };
    }
    case "setBaseMap": {
      return {
        ...state,
        activeLayers: new Map(
          // Remove previous base map
          [...state.activeLayers].filter(
            ([, layer]) => layer.source !== "base-map",
          ),
        ).set(action.payload.id, {
          id: action.payload.id,
          source: "base-map",
          state: { opacity: 1 },
        }),
      };
    }
    case "deactivateLayer": {
      const newActiveLayers = new Map(state.activeLayers);
      newActiveLayers.delete(action.payload.id);
      return {
        ...state,
        activeLayers: newActiveLayers,
      };
    }
    case "clearLayers": {
      const nextActiveLayers = new Map(
        [...state.activeLayers].filter(
          ([, layer]) => layer.source !== action.payload.source,
        ),
      );
      return {
        ...state,
        activeLayers: nextActiveLayers,
      };
    }
    case "setLayerState": {
      const existingLayer = state.activeLayers.get(action.payload.id);

      if (existingLayer) {
        return {
          ...state,
          activeLayers: new Map(state.activeLayers).set(action.payload.id, {
            ...existingLayer,
            state: { ...existingLayer.state, ...action.payload.state },
          }),
        };
      }
      return {
        ...state,
        activeLayers: new Map(state.activeLayers).set(action.payload.id, {
          id: action.payload.id,
          source: action.payload.source,
          state: { opacity: 1, ...action.payload.state } as ActiveLayerState,
        }),
      };
    }
    default:
      return state;
  }
};

export const activeLayersSchema = object({
  activeLayers: array(
    object({
      id: string().required(),
      state: object({
        opacity: number().min(0).max(1).required(),
      }).required(),
    }),
  ).required(),
});

const DEFAULT_ACTIVE_LAYERS_SHARED = [hereMapsLayer.id]
  .map<ActiveLayer>((id) => ({ id, source: "base-map", state: { opacity: 1 } }))
  .concat(
    [
      fireFeatures.id,
      incidentIcons.id,
      potentialImpactsCounts.id,
      stateBorder.id,
    ].map<ActiveLayer>((id) => ({
      id,
      source: "option-layer",
      state: { opacity: 1 },
    })),
  ) satisfies readonly ActiveLayer<ActiveLayerState>[];

export const DEFAULT_ACTIVE_LAYERS = {
  [SessionStorageKeys.ACTIVE_LAYERS_COP]: [
    ...DEFAULT_ACTIVE_LAYERS_SHARED.concat(
      [stateForestPlantationLayer.id].map<ActiveLayer>((id) => ({
        id,
        source: "layers-drawer",
        state: { opacity: 1 },
      })),
    ),
  ],
  [SessionStorageKeys.ACTIVE_LAYERS_FIRE_MODELLING]: [
    ...DEFAULT_ACTIVE_LAYERS_SHARED,
  ],
  [SessionStorageKeys.ACTIVE_LAYERS_STATE_VIEW]: [
    ...DEFAULT_ACTIVE_LAYERS_SHARED,
  ],
} as const satisfies Record<
  SessionStorageKey,
  readonly ActiveLayer<ActiveLayerState>[]
>;

export const DEFAULT_ACTIVE_LAYERS_MAP = Object.fromEntries(
  Object.entries(DEFAULT_ACTIVE_LAYERS).map(([key, layers]) => [
    key as SessionStorageKey,
    new Map<string, ActiveLayer>(layers.map((layer) => [layer.id, layer])),
  ]),
) as Record<SessionStorageKey, Map<string, ActiveLayer>>;

const defaultState: ActiveLayersState = {
  activeLayers:
    DEFAULT_ACTIVE_LAYERS_MAP[SessionStorageKeys.ACTIVE_LAYERS_STATE_VIEW],
};

interface UseActiveLayersParams {
  initialState?: ActiveLayersState;
}

export type UseActiveLayersReturn = {
  activateLayer: <S extends ActiveLayerState>(
    payload: ActivateLayerAction<S>["payload"],
  ) => void;
  clearLayers: (payload: ClearLayersAction["payload"]) => void;
  deactivateLayer: (payload: DeactivateLayerAction["payload"]) => void;
  getLayersBySource: (source: ActiveLayerSource) => Map<string, ActiveLayer>;
  /**
   * Manual method which requires passing the expected shape of state as a
   * generic argument. Useful if the layer options being accessed are dynamic.
   * For example,
   *
   * - Layers constructed dynamically such as FireMapper layers
   * - Layer options behind feature flag.
   */
  getLayerState: <S extends ActiveLayerState>(id: string) => S | undefined;
  /**
   * Shortcut method which can return a typed state object inferred by the
   * `options` property of the passed config. Useful if the layer options being
   * accessed are static.
   */
  getLayerStateFromConfig: <C extends LayerConfig>(
    config: C,
  ) => LayerConfigState<C> | undefined;
  isLayerActive: (layerId: string) => boolean;
  setBaseMap: (payload: SetBaseMapAction["payload"]) => void;
  setLayerState: <S extends ActiveLayerState = ActiveLayerState>(
    payload: SetLayerStateAction<S>["payload"],
  ) => void;
  state: ActiveLayersState;
};

export const useActiveLayers = ({
  initialState = defaultState,
}: UseActiveLayersParams = {}): UseActiveLayersReturn => {
  const [state, dispatch] = useReducer(activeLayersReducer, initialState);

  const activateLayer = useCallback<UseActiveLayersReturn["activateLayer"]>(
    (payload) => {
      dispatch({
        type: "activateLayer",
        payload,
      });
    },
    [],
  );

  const deactivateLayer = useCallback<UseActiveLayersReturn["deactivateLayer"]>(
    (payload) => {
      dispatch({
        type: "deactivateLayer",
        payload,
      });
    },
    [],
  );

  const setBaseMap = useCallback<UseActiveLayersReturn["setBaseMap"]>(
    (payload) => {
      dispatch({
        type: "setBaseMap",
        payload,
      });
    },
    [],
  );

  const setLayerState = useCallback<UseActiveLayersReturn["setLayerState"]>(
    (payload) => {
      dispatch({
        type: "setLayerState",
        payload,
      });
    },
    [],
  );

  const getLayerState = useCallback<UseActiveLayersReturn["getLayerState"]>(
    <S extends ActiveLayerState>(id: string) => {
      const activeLayer = state.activeLayers.get(id);
      if (!activeLayer) {
        return;
      }

      // Note: This does not actually guarantee that the state will be of
      // matching type. Consumers should be wary to ensure they aren't setting
      // unexpected state.
      return activeLayer.state as S;
    },
    [state.activeLayers],
  );

  const getLayerStateFromConfig = useCallback<
    UseActiveLayersReturn["getLayerStateFromConfig"]
  >(
    <C extends LayerConfig>(config: C) => {
      const activeLayer = state.activeLayers.get(config.id);
      if (!activeLayer) {
        return;
      }

      // Note: This does not actually guarantee that the state will be of
      // matching type. Consumers should be wary to ensure they aren't setting
      // unexpected state.
      return activeLayer.state as LayerConfigState<C>;
    },
    [state.activeLayers],
  );

  const getLayersBySource = useCallback<
    UseActiveLayersReturn["getLayersBySource"]
  >(
    (source) => {
      const sourceLayers = [...state.activeLayers.values()].filter(
        (activeLayer) => activeLayer.source === source,
      );
      return new Map(sourceLayers.map((layer) => [layer.id, layer]));
    },
    [state.activeLayers],
  );

  const clearLayers = useCallback<UseActiveLayersReturn["clearLayers"]>(
    (payload) => {
      dispatch({
        type: "clearLayers",
        payload,
      });
    },
    [],
  );

  const isLayerActive = useCallback<UseActiveLayersReturn["isLayerActive"]>(
    (layerId) => {
      return state.activeLayers.has(layerId);
    },
    [state.activeLayers],
  );

  return {
    state,
    activateLayer,
    deactivateLayer,
    clearLayers,
    setBaseMap,
    setLayerState,
    getLayerState,
    getLayerStateFromConfig,
    getLayersBySource,
    isLayerActive,
  } as const;
};
