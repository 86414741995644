import { FlexSpinner } from "@app/design-system";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useGetPermissions } from "../../../../.rest-hooks/permissions";
import type { Permissions } from "../../../../.rest-hooks/types/permissions.yml/permissions";

export interface PrivilegeRedirectProps {
  render: () => React.ReactNode;
  shouldRedirect: (p: Permissions) => boolean;
  url: string;
}

const PrivilegeRedirect = ({
  render,
  shouldRedirect,
  url,
}: PrivilegeRedirectProps) => {
  const router = useRouter();
  const { data, isPending } = useGetPermissions();
  const userPermissions = data?.data.meta.permissions;

  const isRedirecting = userPermissions
    ? shouldRedirect(userPermissions)
    : false;

  useEffect(() => {
    if (isRedirecting) {
      void router.replace(url);
    }
  }, [isRedirecting, router, url]);

  if (isPending || isRedirecting) {
    return <FlexSpinner />;
  }

  return <>{render()}</>;
};

export default PrivilegeRedirect;
