import {
  HeaderLinkTab,
  type HeaderLinkTabItem,
  HeaderLinkTabList,
  type HeaderLinkTabListProps,
  type HeaderLinkTabRenderFunction,
} from "@app/design-system";
import { Item } from "@react-stately/collections";
import omit from "lodash/omit";
import { useRouter } from "next/router";
import NavLink, {
  type NavLinkProps,
  isNavLinkActive,
} from "../../util/NavLink/NavLink";

export type HeaderNavLinkTabItem = HeaderLinkTabItem &
  Omit<NavLinkProps, "href" | "render">;

type HeaderNavLinkTabListProps = Omit<
  HeaderLinkTabListProps<HeaderNavLinkTabItem>,
  "isTabActive" | "renderTab"
>;

const HeaderNavLinkTabList = (props: HeaderNavLinkTabListProps) => {
  const router = useRouter();

  const renderTab: HeaderLinkTabRenderFunction<HeaderNavLinkTabItem> = (
    item,
  ) => {
    const navLinkProps = omit(item.value, ["id"]);
    return (
      <NavLink
        {...navLinkProps}
        legacyBehavior
        key={item.key}
        render={({ isActive }) => (
          <HeaderLinkTab isActive={isActive}>{item.rendered}</HeaderLinkTab>
        )}
      />
    );
  };

  return (
    <HeaderLinkTabList
      {...props}
      isTabActive={(item) =>
        isNavLinkActive({
          as: item.as,
          exact: item.exact,
          href: item.href,
          router,
        })
      }
      renderTab={renderTab}
    >
      {(item) => <Item key={item.id}>{item.title}</Item>}
    </HeaderLinkTabList>
  );
};

export default HeaderNavLinkTabList;
