import { useHasPrivilege } from "../../../hooks/useHasPrivilege";
import AppNavHeader from "../../ui/AppNavHeader/AppNavHeader";
import IncidentsNavTabList from "../../ui/IncidentsNavTabList/IncidentsNavTabList";
import AppLayout from "../AppLayout/AppLayout";

interface IncidentsLayoutProps {
  children: React.ReactNode;
  subheader?: React.ReactNode;
  title: string;
}

const IncidentsLayout = ({
  children,
  subheader,
  title,
}: IncidentsLayoutProps) => {
  const { hasPrivilege } = useHasPrivilege({
    requestedPermissions: ["incident:read", "prediction:read"],
  });

  if (!hasPrivilege) {
    return (
      <AppLayout
        header={
          <AppNavHeader tabList={<IncidentsNavTabList />} title={title} />
        }
      >
        {children}
      </AppLayout>
    );
  }

  return (
    <AppLayout
      header={<AppNavHeader tabList={<IncidentsNavTabList />} title={title} />}
      subheader={subheader}
    >
      {children}
    </AppLayout>
  );
};

export default IncidentsLayout;
