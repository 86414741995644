import getConfig from "next/config";
import StateView from "../components/incidents/StateView/StateView";
import StateViewVisualiser from "../components/incidents/StateView/StateViewVisualiser";
import IncidentsLayout from "../components/layout/IncidentsLayout/IncidentsLayout";
import RootLayout from "../components/layout/RootLayout/RootLayout";
import PrivilegeGate from "../components/ui/PrivilegeGate/PrivilegeGate";
import PrivilegeRedirect from "../components/ui/PrivilegeRedirect/PrivilegeRedirect";
import HeadTitle from "../components/util/HeadTitle/HeadTitle";
import type { NextPageWithLayout } from "./_app.page";

const { publicRuntimeConfig } = getConfig();
const { COP_ENABLED, COP_PAGES_ENABLED } = publicRuntimeConfig;

const StateMapPage: NextPageWithLayout = () => {
  return (
    <>
      <HeadTitle>{COP_ENABLED ? "State View" : "State Map"}</HeadTitle>
      <PrivilegeRedirect
        shouldRedirect={(p) =>
          COP_PAGES_ENABLED &&
          p.includes("incident:read") &&
          !p.includes("prediction:read")
        }
        url="/cop"
        render={() => (
          <PrivilegeGate
            features={["incident:read", "prediction:read"]}
            render={() => (
              <StateViewVisualiser>
                <StateView />
              </StateViewVisualiser>
            )}
          />
        )}
      />
    </>
  );
};

StateMapPage.getLayout = (page) => {
  return (
    <RootLayout>
      <IncidentsLayout title={COP_ENABLED ? "State View" : "State Map"}>
        {page}
      </IncidentsLayout>
    </RootLayout>
  );
};

export default StateMapPage;
